import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  HostListener,
  OnInit,
} from '@angular/core';
import { MatTableModule } from '@angular/material/table';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatSortModule } from '@angular/material/sort';
import { FinanceService } from '../../services/finance.service';
import { CurrencyPipe, DatePipe, NgForOf, NgIf } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';

export interface TransactionsHistoryItem {
  id: string;
  to: number;
  from: number;
  value: number;
  amount: number;
  reasonKey: string;
  createdAt: number;
}

@Component({
  selector: 'app-transactions-history',
  templateUrl: './transactions-history.component.html',
  styleUrl: './transactions-history.component.scss',
  standalone: true,
  imports: [
    MatTableModule,
    MatPaginatorModule,
    MatSortModule,
    CurrencyPipe,
    NgForOf,
    NgIf,
    DatePipe,
    TranslateModule,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TransactionsHistoryComponent implements OnInit {
  transactionsByDate: { [date: string]: TransactionsHistoryItem[] } = {};
  total: number = 0;
  page: number = 1;
  size: number = 10;
  isLoading: boolean = false;
  allDataLoaded: boolean = false;

  constructor(
    private readonly financeService: FinanceService,
    private readonly changeDetector: ChangeDetectorRef,
  ) {}

  ngOnInit() {
    this.loadTransactions();
  }

  private groupByDate(transactions: TransactionsHistoryItem[]): void {
    transactions.forEach((transaction) => {
      const date = new Date(transaction.createdAt).toLocaleDateString('ru-RU', {
        year: 'numeric',
        month: 'long',
        day: 'numeric',
      });

      if (!this.transactionsByDate[date]) {
        this.transactionsByDate[date] = [];
      }
      this.transactionsByDate[date].push(transaction);
    });
  }

  loadTransactions(): void {
    if (this.isLoading || this.allDataLoaded) return;

    this.isLoading = true;
    this.financeService
      .getTransactions(this.page, this.size)
      .subscribe((response) => {
        if (response.transactions.length === 0) {
          this.allDataLoaded = true;
        } else {
          this.groupByDate(response.transactions);
          this.total = response.total;
          this.page++;
          this.isLoading = false;
        }
        this.changeDetector.detectChanges();
      });
  }

  getTransactionDates(): string[] {
    return Object.keys(this.transactionsByDate);
  }

  @HostListener('window:scroll', [])
  onScroll(): void {
    const scrollThreshold = 300;
    const pos = window.scrollY + window.innerHeight;
    const max = document.documentElement.scrollHeight;
    if (
      pos + scrollThreshold >= max &&
      !this.isLoading &&
      !this.allDataLoaded
    ) {
      this.loadTransactions();
    }
  }
}
