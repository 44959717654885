import { Component, inject } from '@angular/core';
import {
  MAT_DIALOG_DATA,
  MatDialogActions,
  MatDialogContent,
  MatDialogRef,
  MatDialogTitle,
} from '@angular/material/dialog';
import { MatFormField, MatFormFieldModule } from '@angular/material/form-field';
import { MatInput } from '@angular/material/input';
import { FormsModule } from '@angular/forms';
import { MatButton } from '@angular/material/button';

@Component({
  selector: 'app-withdrawal-dialog',
  standalone: true,
  imports: [
    MatDialogTitle,
    MatDialogContent,
    MatFormField,
    MatInput,
    FormsModule,
    MatDialogActions,
    MatButton,
    MatFormFieldModule,
  ],
  templateUrl: './withdrawal-dialog.component.html',
  styleUrl: './withdrawal-dialog.component.scss',
})
export class WithdrawalDialogComponent {
  data = inject(MAT_DIALOG_DATA);

  amountToWithdraw!: number;

  constructor(public dialogRef: MatDialogRef<WithdrawalDialogComponent>) {}

  onCancel(): void {
    this.dialogRef.close();
  }

  onConfirm(): void {
    this.dialogRef.close(this.amountToWithdraw);
  }
}
