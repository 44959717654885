export var SocialProviderEnum;
(function (SocialProviderEnum) {
  SocialProviderEnum["Google"] = "google";
  SocialProviderEnum["Github"] = "github";
  SocialProviderEnum["Apple"] = "apple";
  SocialProviderEnum["Facebook"] = "facebook";
  SocialProviderEnum["X"] = "x";
  SocialProviderEnum["Discord"] = "discord";
  SocialProviderEnum["Farcaster"] = "farcaster";
})(SocialProviderEnum || (SocialProviderEnum = {}));
