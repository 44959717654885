import { Component, OnInit } from '@angular/core';
import { Chart } from 'chart.js/auto';
import { FinanceService } from '../../../services/finance.service';
import { BaseChartDirective } from 'ng2-charts';

@Component({
  selector: 'app-crypto-chart',
  standalone: true,
  imports: [BaseChartDirective],
  templateUrl: './crypto-chart.component.html',
  styleUrl: './crypto-chart.component.scss',
})
export class CryptoChartComponent implements OnInit {
  constructor() {}

  ngOnInit() {}
}
