import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';
import { FinanceSettings } from '../common/models';

@Injectable({
  providedIn: 'root',
})
export class SettingsService {
  private apiUrl = `${environment.apiUrl}/settings`;

  constructor(private readonly http: HttpClient) {}

  getWeb3Settings(): Observable<FinanceSettings> {
    return this.http.get<FinanceSettings>(`${this.apiUrl}/web3Settings`);
  }
}
