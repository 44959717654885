import { Injectable } from '@angular/core';
import { FinanceService } from './finance.service';
import { ERC20ABI } from './constants';
import { Address, Chain, formatUnits, parseEther } from 'viem';
import {
  Config,
  getAccount,
  readContract,
  reconnect,
  waitForTransaction,
  writeContract,
} from '@wagmi/core';
import {
  createWeb3Modal,
  defaultWagmiConfig,
  Web3Modal,
} from '@web3modal/wagmi';
import { BehaviorSubject, of } from 'rxjs';
import { Store } from '@ngrx/store';
import { setBalance } from '../store/actions/player.actions';
import { SettingsService } from './settings.service';
import { LoadingService } from '../common/services/loading/loading.service';
import { tap } from 'rxjs/operators';

const USER_EVENTS = [
  'CONNECT_SUCCESS',
  'CONNECT_ERROR',
  'DISCONNECT_SUCCESS',
  'DISCONNECT_ERROR',
  'ERROR_FETCH_TRANSACTIONS',
  'SWITCH_NETWORK',
  'SEND_INITIATED',
  'SEND_SUCCESS',
  'SEND_ERROR',
];

@Injectable({
  providedIn: 'root',
})
export class WalletService {
  modal!: Web3Modal;
  modalInitiated = false;
  private connected$$: BehaviorSubject<boolean> = new BehaviorSubject(false);
  connected$ = this.connected$$.asObservable();
  private walletConnected$$: BehaviorSubject<boolean> = new BehaviorSubject(
    false,
  );
  walletConnected$ = this.walletConnected$$.asObservable();
  private modalState$$: BehaviorSubject<string | null> = new BehaviorSubject<
    string | null
  >(null);
  modalState$ = this.modalState$$.asObservable();

  depositWalletAddress!: string;
  walletConnectProjectId!: string;
  smartContractAddress!: string;
  currencyId!: string;
  network!: Chain;

  config!: Config;
  constructor(
    private readonly financeService: FinanceService,
    private readonly store: Store,
    private readonly settingsService: SettingsService,
    private readonly loadingService: LoadingService,
  ) {
    this.loadingService.setLoading(true);
    this.initWallet();
    this.getWeb3Settings();
  }

  initWallet() {
    this.getWeb3Settings().subscribe({
      next: (data) => {
        this.smartContractAddress = data?.fiatCurrencies[0].address;
        this.depositWalletAddress = data?.paymentData?.walletAddress ?? '';
        this.walletConnectProjectId = data.walletConnectProjectId;
        this.currencyId = data?.fiatCurrencies[0].id ?? '';
        this.network = data.web3Settings;
        this.config = defaultWagmiConfig({
          chains: [this.network],
          projectId: this.walletConnectProjectId,
          metadata: {
            name: 'Meta GG',
            description: 'Meta GG crypto portal',
            url: 'https://meta-game-dev-panel.ggsinternal.space',
            icons: ['https://avatars.githubusercontent.com/u/37784886'],
          },
          enableInjected: true,
          enableWalletConnect: true,
          auth: {
            email: false,
          },
        });
        reconnect(this.config).then((data) => {
          if (data.length) {
            this.updateExternalBalance();
            this.connected$$.next(true);
          }
          this.walletConnected$$.next(true);
        });
        this.modal = createWeb3Modal({
          wagmiConfig: this.config,
          projectId: this.walletConnectProjectId,
          themeMode: 'light',
          includeWalletIds: [
            'c57ca95b47569778a828d19178114f4db188b89b763c899ba0be274e97267d96',
            '4622a2b2d6af1c9844944291e5e7351a6aa24cd7b23099efac1b2fd875da31a0',
            'fd20dc426fb37566d803205b19bbc1d4096b248ac04548e3cfb6b3a38bd033aa',
            'e9ff15be73584489ca4a66f64d32c4537711797e30b6660dbcb71ea72a42b1f4',
          ],
        });
      },
    });
  }

  connectWallet() {
    this.modal.open().then(() => (this.modalInitiated = true));
    if (!this.modalInitiated) {
      this.modal.subscribeEvents((state) => {
        if (state.data.event === 'CONNECT_ERROR') {
          this.connected$$.next(false);
        }

        if (USER_EVENTS.includes(state.data.event))
          this.modalState$$.next(state.data.event);
      });
      this.modal.subscribeWalletInfo((data) => {
        if (data) {
          this.connected$$.next(true);
          this.updateExternalBalance();
        } else {
          this.connected$$.next(false);
        }
      });
      // this.modal.subscribeState((state) => {});
    }
  }

  private async updateExternalBalance() {
    const { address } = getAccount(this.config);
    if (!address) return;
    const balance = await this.getBalance(address);
    this.store.dispatch(setBalance({ balance }));
  }

  getConnectionState() {
    return this.connected$;
  }

  getWalletState() {
    return this.walletConnected$;
  }

  getWalletAddress() {
    const { address } = getAccount(this.config);
    return address;
  }

  async sendERC20Transaction(amount: string) {
    const address = this.getWalletAddress();

    if (!address) return;
    const balance = await this.getBalance(address);

    if (this.insufficientFunds(balance, amount)) {
      this.modalState$$.next('INSUFFICIENT_DEPOSIT_FUNDS');
      return;
    }

    const hash = await writeContract(this.config, {
      abi: ERC20ABI,
      address: this.smartContractAddress as Address,
      functionName: 'transfer',
      args: [this.depositWalletAddress, parseEther(amount)],
    });
    this.modalState$$.next('DEPOSIT_INITIATED');
    const receipt = await waitForTransaction(this.config, {
      hash: hash,
    });
    this.registerDepositing(hash, address, amount);

    if (receipt.status === 'success') {
      this.updateExternalBalance();
      return true;
    } else {
      this.modalState$$.next('DEPOSIT_FAILED');
      return false;
    }
  }

  createWithdrawal(amount: string) {
    const { address } = getAccount(this.config);
    if (!address) return of();
    return this.financeService.createWithdrawal(
      address,
      amount,
      this.currencyId,
    );
  }

  private async getBalance(address: Address) {
    const data = (await readContract(this.config, {
      abi: ERC20ABI,
      address: this.smartContractAddress as Address,
      functionName: 'balanceOf',
      args: [address],
    })) as bigint;
    return formatUnits(data, 18);
  }

  private registerDepositing(
    transactionHash: string,
    from: string,
    amount: string,
  ) {
    this.financeService
      .registerDepositing(transactionHash, from, amount, this.currencyId)
      .subscribe({ next: () => this.modalState$$.next('DEPOSIT_SUCCESS') });
  }

  private insufficientFunds(str1: string, str2: string): boolean {
    const num1 = parseFloat(str1);
    const num2 = parseFloat(str2);
    return num1 < num2;
  }

  private getWeb3Settings() {
    return this.settingsService.getWeb3Settings();
  }
}
