<h1 mat-dialog-title style="text-align: center; color: white">
  Вывод на кошелек:
  <br />
  <div class="address">
    {{ data.address }}
  </div>
</h1>
<div mat-dialog-content>
  <div class="container inputs-button">
    <form>
      <div class="form-group">
        <label style="color: white" for="input" class="mb-2">
          Введите сумму
        </label>
        <div class="input-container">
          <input
            [(ngModel)]="amountToWithdraw"
            type="number"
            name="amount"
            required
            class="custom-input"
            id="input"
            placeholder="Enter amount" />
          <img class="input-icon" src="assets/images/coin.svg" alt="icon" />
        </div>
      </div>
    </form>
  </div>
</div>
<div mat-dialog-actions class="d-flex justify-content-center">
  <button class="custom-button" (click)="onConfirm()">Вывести</button>
</div>
