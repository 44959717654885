import {
  ChangeDetectionStrategy,
  Component,
  DestroyRef,
  inject,
  OnInit,
} from '@angular/core';
import { WalletService } from '../../services/wallet.service';
import { AsyncPipe } from '@angular/common';
import { MatButton } from '@angular/material/button';
import { MatDialog } from '@angular/material/dialog';
import { Observable, of } from 'rxjs';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TranslateService } from '@ngx-translate/core';
import { DepositDialogComponent } from './deposit-dialog/deposit-dialog.component';
import { WithdrawalDialogComponent } from './withdrawal-dialog/withdrawal-dialog.component';
import { AuthService } from '../../common/guards/auth.service';
import { catchError, filter, switchMap, tap } from 'rxjs/operators';
import { HttpErrorResponse } from '@angular/common/http';
import { AppSettingsService } from '../../services/app.settings.service';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { RouterLink } from '@angular/router';

@Component({
  selector: 'app-wallet',
  standalone: true,
  imports: [AsyncPipe, MatButton, RouterLink],
  templateUrl: './wallet.component.html',
  styleUrl: './wallet.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class WalletComponent implements OnInit {
  private destroyRef = inject(DestroyRef);

  amountToDeposit!: number;
  amountToWithdraw!: number;
  connected!: Observable<boolean>;
  walletConnected!: Observable<boolean>;

  constructor(
    public walletService: WalletService,
    private readonly authService: AuthService,
    public dialog: MatDialog,
    private _snackBar: MatSnackBar,
    private readonly translate: TranslateService,
    private readonly appSettingsService: AppSettingsService,
  ) {}

  ngOnInit(): void {
    this.connected = this.walletService.getConnectionState();
    this.walletConnected = this.walletService.getWalletState();
    this.walletService.modalState$
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe({
        next: (value) => {
          if (value) {
            this.openSnackBar(value);
          }
        },
      });
  }

  connectWallet() {
    this.walletService.connectWallet();
  }

  registerDeposit(): void {
    const dialogRef = this.dialog.open(DepositDialogComponent);

    dialogRef
      .afterClosed()
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((result) => {
        if (result) {
          this.amountToDeposit = result;
          this.walletService
            .sendERC20Transaction(this.amountToDeposit.toString())
            .then((success) => {
              if (success) {
                this.appSettingsService.getPlayerModel();
              }
              this.amountToDeposit = 0;
            });
        }
      });
  }

  createWithdrawal(): void {
    const dialogRef = this.dialog.open(WithdrawalDialogComponent, {
      data: {
        address: this.walletService.getWalletAddress(),
      },
    });
    dialogRef
      .afterClosed()
      .pipe(
        takeUntilDestroyed(this.destroyRef),
        filter((result) => !!result),
        tap((result) => (this.amountToWithdraw = result)),
        switchMap((result) =>
          this.walletService.createWithdrawal(result.toString()).pipe(
            tap(() => {
              this.openSnackBar('Запрос на вывод зарегистрирован');
            }),
            catchError((error: HttpErrorResponse) => {
              if (error.status === 402) {
                this.openSnackBar(error.error.message);
              } else {
                this.openSnackBar(error.message ?? 'Ошибка при запросе вывода');
              }
              this.amountToWithdraw = 0;
              return of();
            }),
          ),
        ),
      )
      .subscribe({ next: () => this.appSettingsService.getPlayerModel() });
  }

  logout() {
    this.authService.logout();
  }

  private openSnackBar(message: string) {
    const translation = this.translate.instant(message);
    this._snackBar.open(translation, 'OK', {
      duration: 5000,
    });
  }
}
