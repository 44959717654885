<div class="container mt-4">
  <div *ngFor="let date of getTransactionDates()">
    <h5 class="card-title">{{ date }}</h5>

    <div *ngFor="let transaction of transactionsByDate[date]" class="mb-3">
      <div
        class="transaction d-flex justify-content-between align-items-center">
        <div class="d-flex align-items-center">
          <img
            src="assets/images/operation.svg"
            alt="Icon"
            class="me-2"
            width="30" />
          <div>
            <div>{{ transaction.reasonKey | translate }}</div>
            <small>
              {{ transaction.createdAt | date: 'shortTime' }}
            </small>
          </div>
        </div>
        <div>{{ transaction.amount }}</div>
      </div>
    </div>
  </div>
  <div class="text-center" *ngIf="!allDataLoaded">
    <button
      class="custom-button"
      (click)="loadTransactions()"
      [disabled]="isLoading">
      {{ isLoading ? 'Загрузка...' : 'Далее' }}
    </button>
  </div>
  <div class="text-center mt-3" *ngIf="isLoading && !allDataLoaded">
    <div class="spinner-border text-dark" role="status">
      <span class="sr-only">Загрузка...</span>
    </div>
  </div>
</div>
