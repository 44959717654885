import { ChangeDetectionStrategy, Component } from '@angular/core';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { Store } from '@ngrx/store';
import {
  selectBalance,
  selectPlayerID,
  selectPlayerWrapper,
} from '../../store/selectors/player.selector';
import { version } from '../../../../package.json';
import { filter, map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import Helper from '../../common/helpers/helper';
import { Token } from '../../common/models';
import { PlayerWrapper } from '../../common/models/wrappers/player.wrapper';
import {
  MatCard,
  MatCardActions,
  MatCardContent,
  MatCardHeader,
  MatCardTitle,
} from '@angular/material/card';
import { MatIcon } from '@angular/material/icon';
import { AsyncPipe } from '@angular/common';
import { WalletComponent } from '../wallet/wallet.component';
import { CryptoChartComponent } from './crypto-chart/crypto-chart.component';
import { MatButton, MatIconButton } from '@angular/material/button';
import { FormsModule } from '@angular/forms';
import { MatError, MatFormField, MatLabel } from '@angular/material/form-field';
import { MatInput } from '@angular/material/input';
import { TransactionsHistoryComponent } from '../transactions-history/transactions-history.component';

@Component({
  selector: 'app-dashboard',
  standalone: true,
  imports: [
    MatCard,
    TranslateModule,
    MatIcon,
    AsyncPipe,
    WalletComponent,
    CryptoChartComponent,
    MatButton,
    MatIconButton,
    FormsModule,
    MatFormField,
    MatInput,
    MatLabel,
    MatCardActions,
    MatCardContent,
    MatCardHeader,
    MatCardTitle,
    MatError,
    TransactionsHistoryComponent,
  ],
  templateUrl: './dashboard.component.html',
  styleUrl: './dashboard.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DashboardComponent {
  filledIndex = 1;
  version = version;
  playerId = this.store.select(selectPlayerID);

  playerWrapper: Observable<PlayerWrapper> = this.store
    .select(selectPlayerWrapper)
    .pipe(
      filter((data: PlayerWrapper | null) => data !== null),
    ) as Observable<PlayerWrapper>;

  name: Observable<string | undefined> = this.playerWrapper.pipe(
    map((data) => data?.name),
  );

  externalBalance: Observable<string | null> = this.store.select(selectBalance);

  currencies: Observable<Token[]> = this.playerWrapper.pipe(
    map((data) => {
      if (data?.currencies) {
        return Helper.transformRecord(data.currencies);
      }
      return [];
    }),
  );

  constructor(
    private readonly translate: TranslateService,
    private readonly store: Store,
  ) {}

  fillOnly(index: number): void {
    this.filledIndex = index;
  }
}
