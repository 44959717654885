export type EntityData = { [key: string]: unknown };

export abstract class EntityWrapper<T extends EntityData> {
  constructor(protected _model: T) {}

  get model() {
    return this._model;
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  static createDefault(...args: unknown[]) {
    return {};
  }

  has<FieldName extends keyof T>(key: FieldName): boolean {
    return this._model[key] !== undefined;
  }

  get<FieldName extends keyof T>(key: FieldName): T[FieldName] {
    if (!this.has(key)) {
      throw new Error(`Unexpected field '${key as string}'`);
    }
    return this._model[key];
  }

  getOr<FieldName extends keyof T>(
    key: FieldName,
    defaultValue: T[FieldName],
  ): T[FieldName] {
    if (!this.has(key)) {
      return defaultValue;
    }
    return this._model[key];
  }

  set<FieldName extends keyof T>(key: FieldName, value: T[FieldName]): void {
    this._model[key] = value;
  }

  toJson() {
    return this._model;
  }

  toString() {
    return JSON.stringify(this.toJson());
  }

  protected isRecordEmpty<T>(record: Record<string, T>): boolean {
    return Object.keys(record).length === 0;
  }
}
