import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';
import { TPlayer } from '../common/models/player.model';

@Injectable({
  providedIn: 'root',
})
export class PlayersService {
  private apiUrl = `${environment.apiUrl}/players`;

  constructor(private readonly http: HttpClient) {}

  getPlayerModel(): Observable<{ playerModel: TPlayer }> {
    return this.http.post<{ playerModel: TPlayer }>(
      `${this.apiUrl}/get-player-model`,
      {},
    );
  }
}
