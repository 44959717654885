import { createFeatureSelector, createSelector } from '@ngrx/store';
import { PlayerState } from '../reducers/player.reducer';

export const selectPlayerState = createFeatureSelector<PlayerState>('player');

export const selectPlayerID = createSelector(
  selectPlayerState,
  (state: PlayerState) => state.playerId,
);

export const selectPlayerWrapper = createSelector(
  selectPlayerState,
  (state: PlayerState) => state.playerWrapper,
);

export const selectBalance = createSelector(
  selectPlayerState,
  (state: PlayerState) => state.balance,
);
