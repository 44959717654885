var __decorate = this && this.__decorate || function (decorators, target, key, desc) {
  var c = arguments.length,
    r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc,
    d;
  if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
  return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { AccountController, EventsController, RouterController } from '@web3modal/core';
import { customElement } from '@web3modal/ui';
import { LitElement, html } from 'lit';
import styles from './styles.js';
import { state } from 'lit/decorators.js';
import { W3mFrameRpcConstants } from '@web3modal/wallet';
let W3mAccountTokensWidget = class W3mAccountTokensWidget extends LitElement {
  constructor() {
    super();
    this.unsubscribe = [];
    this.tokenBalance = AccountController.state.tokenBalance;
    this.unsubscribe.push(...[AccountController.subscribe(val => {
      this.tokenBalance = val.tokenBalance;
    })]);
  }
  disconnectedCallback() {
    this.unsubscribe.forEach(unsubscribe => unsubscribe());
  }
  render() {
    return html`${this.tokenTemplate()}`;
  }
  tokenTemplate() {
    if (this.tokenBalance && this.tokenBalance?.length > 0) {
      return html`<wui-flex class="contentContainer" flexDirection="column" gap="xs">
        ${this.tokenItemTemplate()}
      </wui-flex>`;
    }
    return html` <wui-flex flexDirection="column" gap="xs"
      ><wui-list-description
        @click=${this.onBuyClick.bind(this)}
        text="Buy Crypto"
        description="Easy with card or bank account"
        icon="card"
        iconColor="success-100"
        iconBackgroundColor="success-100"
        tag="popular"
      ></wui-list-description
      ><wui-list-description
        @click=${this.onReceiveClick.bind(this)}
        text="Receive funds"
        description="Transfer tokens on your wallet"
        icon="arrowBottomCircle"
        iconColor="fg-200"
        iconBackgroundColor="fg-200"
      ></wui-list-description
    ></wui-flex>`;
  }
  tokenItemTemplate() {
    return this.tokenBalance?.map(token => html`<wui-list-token
          tokenName=${token.name}
          tokenImageUrl=${token.iconUrl}
          tokenAmount=${token.quantity.numeric}
          tokenValue=${token.value}
          tokenCurrency=${token.symbol}
        ></wui-list-token>`);
  }
  onReceiveClick() {
    RouterController.push('WalletReceive');
  }
  onBuyClick() {
    EventsController.sendEvent({
      type: 'track',
      event: 'SELECT_BUY_CRYPTO',
      properties: {
        isSmartAccount: AccountController.state.preferredAccountType === W3mFrameRpcConstants.ACCOUNT_TYPES.SMART_ACCOUNT
      }
    });
    RouterController.push('OnRampProviders');
  }
};
W3mAccountTokensWidget.styles = styles;
__decorate([state()], W3mAccountTokensWidget.prototype, "tokenBalance", void 0);
W3mAccountTokensWidget = __decorate([customElement('w3m-account-tokens-widget')], W3mAccountTokensWidget);
export { W3mAccountTokensWidget };
