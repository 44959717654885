import { EntityWrapper } from '../../wrappers/entity.wrapper';
import { TPlayer } from '../player.model';

export class PlayerWrapper extends EntityWrapper<TPlayer> {
  get playerId() {
    return this.get('playerId');
  }

  get name() {
    return this.get('profile').name;
  }

  get currencies() {
    return this.get('fiatCurrencies').map;
  }
}
