<div class="d-flex justify-content-center">
  <img src="assets/images/LOGO2.svg" class="logo-img" />
  <div class="logo-balance">
    @if (currencies | async; as currencies) {
      <div>{{ currencies[0].amount }}</div>
    }
  </div>
</div>

<div class="d-flex header">
  <img
    id="settings-icon"
    src="assets/images/Frame%20339.svg"
    alt="Icon"
    width="30"
    style="margin-left: 10px" />
  @if (playerWrapper | async; as playerWrapper) {
    <div class="user-id">User {{ playerWrapper.name }}</div>
  }
  <img
    id="menu-icon"
    src="assets/images/Frame%20340.svg"
    alt="Icon"
    width="30"
    style="margin-right: 10px" />
</div>

<div class="container main-container d-flex flex-column">
  <div class="icon-container">
    <div class="icon" (click)="fillOnly(0)" [class.filled]="filledIndex === 0">
      <div class="icon-circle" [class.filled]="filledIndex === 0">
        @if (filledIndex === 0) {
          <img
            class="icon-symbol"
            src="assets/images/icons/balance_w.svg"
            alt="Icon 2" />
        } @else {
          <img
            class="icon-symbol"
            src="assets/images/icons/balance_p.svg"
            alt="Icon 2" />
        }
      </div>
      <div class="icon-label">Курс токена</div>
    </div>

    <div class="icon" (click)="fillOnly(1)" [class.filled]="filledIndex === 1">
      <div class="icon-circle" [class.filled]="filledIndex === 1">
        @if (filledIndex === 1) {
          <img
            class="icon-symbol"
            src="assets/images/icons/dollar_w.svg"
            alt="Icon 2" />
        } @else {
          <img
            class="icon-symbol"
            src="assets/images/icons/dollar_p.svg"
            alt="Icon 2" />
        }
      </div>
      <div class="icon-label">Вывести</div>
    </div>

    <div class="icon" (click)="fillOnly(2)" [class.filled]="filledIndex === 2">
      <div class="icon-circle" [class.filled]="filledIndex === 2">
        @if (filledIndex === 2) {
          <img
            class="icon-symbol"
            src="assets/images/icons/id_w.svg"
            alt="Icon 2" />
        } @else {
          <img
            class="icon-symbol"
            src="assets/images/icons/id_p.svg"
            alt="Icon 2" />
        }
      </div>
      <div class="icon-label">Операции</div>
    </div>
  </div>
  @switch (filledIndex) {
    @case (0) {
      <div class="row">
        <app-crypto-chart></app-crypto-chart>
      </div>
    }
    @case (1) {
      <app-wallet></app-wallet>
    }
    @case (2) {
      <app-transactions-history></app-transactions-history>
    }
  }
</div>

<div class="col">
  <mat-card class="text-center py-4">
    <h4>version: {{ version }}</h4>
  </mat-card>
</div>
