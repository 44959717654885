<div class="mt-2">
  @if (connected | async) {
    <div class="button-container">
      <div class="row">
        <div class="col">
          <button class="create-wallet-button" (click)="registerDeposit()">
            Пополнить
          </button>
        </div>
        <div class="col">
          <button class="custom-button" (click)="createWithdrawal()">
            Вывести
          </button>
        </div>
      </div>
      <button class="custom-button" (click)="connectWallet()">
        Сменить кошелек
      </button>
    </div>
  } @else {
    <div class="button-container">
      <button class="custom-button" (click)="connectWallet()">
        <img src="./assets/images/wc.svg" class="button-icon" />
        <span class="button-text">Подключить кошелек</span>
      </button>
      <button class="create-wallet-button" [routerLink]="'/wallet-create'">
        Создать кошелек
      </button>
    </div>
  }
</div>
