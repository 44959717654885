import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { AuthService } from '../../common/guards/auth.service';
import { setPlayerId } from '../../store/actions/player.actions';
import { AppSettingsService } from '../../services/app.settings.service';

@Component({
  selector: 'app-init-settings',
  standalone: true,
  imports: [],
  template: ``,
})
export class InitSettingsComponent implements OnInit {
  constructor(
    private readonly store: Store,
    private readonly authService: AuthService,
    private readonly appSettingsService: AppSettingsService,
  ) {}

  ngOnInit(): void {
    this.storePlayerData();
  }

  private storePlayerData() {
    const playerId = localStorage.getItem('playerId');
    if (playerId) {
      this.store.dispatch(setPlayerId({ playerId: playerId }));
      this.appSettingsService.initiateSettings();
    } else this.authService.logout();
  }
}
