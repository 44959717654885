import { createAction, props } from '@ngrx/store';
import { PlayerWrapper } from '../../common/models/wrappers/player.wrapper';

export const setPlayerId = createAction(
  '[Player] Set Player ID',
  props<{ playerId: string }>(),
);

export const setPlayerWrapper = createAction(
  '[Player] Set Player Wrapper',
  props<{ playerWrapper: PlayerWrapper }>(),
);

export const setBalance = createAction(
  '[Player] Set Balance',
  props<{ balance: string }>(),
);

export const clearPlayerId = createAction('[Player] Clear Player ID');
