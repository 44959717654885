<div class="d-flex justify-content-center">
  <img src="assets/images/LOGO.svg" class="logo-img" />
</div>

<div class="container main-container d-flex flex-column justify-content-center">
  <div class="d-flex justify-content-start">
    <div class="logo-text">
      GG Meta
      <br />
      Portal
    </div>
  </div>
  <form (ngSubmit)="login()" class="form-group-inputs">
    <div class="form-group">
      <label for="exampleInputEmail1">Почта</label>
      <input
        [(ngModel)]="email"
        type="email"
        name="email"
        class="form-control custom-input"
        id="exampleInputEmail1"
        aria-describedby="emailHelp"
        placeholder="Enter email" />
      @if (loginError) {
        <mat-error>Неверный логин или пароль</mat-error>
      }
    </div>
    <div class="form-group">
      <label for="exampleInputPassword1">Пароль</label>
      <input
        [(ngModel)]="password"
        type="password"
        name="password"
        class="form-control custom-input"
        id="exampleInputPassword1"
        placeholder="Password" />
    </div>
    <button class="custom-button" type="submit">Войти</button>
  </form>
  <a
    class="d-flex justify-content-center"
    href="/register"
    style="
      margin-top: 35px;
      color: #fc4f56;
      font-size: 24px;
      font-family: Noto Sans;
      font-weight: 400;
      text-decoration: underline;
    ">
    Зарегистрироваться
  </a>
</div>
