import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { catchError, tap } from 'rxjs/operators';
import { Router } from '@angular/router';
import { JwtHelperService } from '@auth0/angular-jwt';
import { of, throwError } from 'rxjs';
import { environment } from '../../../environments/environment';
import { Store } from '@ngrx/store';
import { setPlayerId } from '../../store/actions/player.actions';
import { AppSettingsService } from '../../services/app.settings.service';

type AuthResult = {
  access_token: string;
  refresh_token: string;
  playerId: string;
};

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  private apiUrl = `${environment.apiUrl}/auth`;
  private jwtHelper = new JwtHelperService();

  constructor(
    private http: HttpClient,
    private router: Router,
    private store: Store,
    private appSettingsService: AppSettingsService,
  ) {}

  login(email: string, password: string) {
    return this.http
      .post<{
        access_token: string;
        refresh_token: string;
        playerId: string;
        message?: string;
      }>(`${this.apiUrl}/login`, { email, password })
      .pipe(
        tap((response) => {
          if (response.message === 'Invalid email or password') {
            throw new Error('Invalid email or password');
          }
          this.store.dispatch(setPlayerId({ playerId: response.playerId }));
          this.setSession(response);
          this.appSettingsService.initiateSettings();
        }),
      );
  }

  logout() {
    localStorage.removeItem('token');
    localStorage.removeItem('refresh_token');
    localStorage.removeItem('playerId');
    this.router.navigate(['/login']);
  }

  isLoggedIn() {
    const token = localStorage.getItem('token');
    return token && !this.isTokenExpired();
  }

  isTokenExpired(): boolean {
    const token = localStorage.getItem('token');
    return this.jwtHelper.isTokenExpired(token);
  }

  refreshToken() {
    const refreshToken = localStorage.getItem('refresh_token');
    if (!refreshToken) {
      this.logout();
      return of(false);
    }
    return this.http
      .post<{
        access_token: string;
      }>(`${this.apiUrl}/refresh`, { refreshToken })
      .pipe(
        tap((response) => localStorage.setItem('token', response.access_token)),
        catchError((error) => {
          this.logout();
          return throwError(error);
        }),
      );
  }

  private setSession(authResult: AuthResult) {
    localStorage.setItem('token', authResult.access_token);
    localStorage.setItem('refresh_token', authResult.refresh_token);
    localStorage.setItem('playerId', authResult.playerId);
  }
}
