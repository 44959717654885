import { Component } from '@angular/core';
import { AsyncPipe } from '@angular/common';
import { TransactionsHistoryComponent } from '../transactions-history/transactions-history.component';
import { WalletComponent } from '../wallet/wallet.component';
import { Observable } from 'rxjs';
import { PlayerWrapper } from '../../common/models/wrappers/player.wrapper';
import { selectPlayerWrapper } from '../../store/selectors/player.selector';
import { filter, map } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';
import { Store } from '@ngrx/store';
import { Token } from '../../common/models';
import Helper from '../../common/helpers/helper';

@Component({
  selector: 'app-wallet-create',
  standalone: true,
  imports: [AsyncPipe, TransactionsHistoryComponent, WalletComponent],
  templateUrl: './wallet-create.component.html',
  styleUrl: './wallet-create.component.scss',
})
export class WalletCreateComponent {
  constructor(
    private readonly translate: TranslateService,
    private readonly store: Store,
  ) {}

  goToWalletUrl(url: string) {
    window.open(url, '_blank');
  }

  playerWrapper: Observable<PlayerWrapper> = this.store
    .select(selectPlayerWrapper)
    .pipe(
      filter((data: PlayerWrapper | null) => data !== null),
    ) as Observable<PlayerWrapper>;

  currencies: Observable<Token[]> = this.playerWrapper.pipe(
    map((data) => {
      if (data?.currencies) {
        return Helper.transformRecord(data.currencies);
      }
      return [];
    }),
  );
}
