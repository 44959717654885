import { Injectable } from '@angular/core';
import { setPlayerWrapper } from '../store/actions/player.actions';
import { PlayerWrapper } from '../common/models/wrappers/player.wrapper';
import { PlayersService } from './players.service';
import { Store } from '@ngrx/store';

@Injectable({
  providedIn: 'root',
})
export class AppSettingsService {
  constructor(
    private readonly store: Store,
    private readonly playersService: PlayersService,
  ) {}

  initiateSettings() {
    this.getPlayerModel();
  }

  getPlayerModel() {
    this.playersService.getPlayerModel().subscribe({
      next: (data) => {
        this.store.dispatch(
          setPlayerWrapper({
            playerWrapper: new PlayerWrapper(data.playerModel),
          }),
        );
      },
    });
  }
}
