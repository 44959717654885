import { ChangeDetectionStrategy, Component } from '@angular/core';
import {
  MatDialogActions,
  MatDialogContent,
  MatDialogRef,
  MatDialogTitle,
} from '@angular/material/dialog';
import { MatFormField, MatFormFieldModule } from '@angular/material/form-field';
import { FormsModule } from '@angular/forms';
import { MatInput } from '@angular/material/input';
import { MatButton } from '@angular/material/button';

@Component({
  selector: 'app-deposit-dialog',
  standalone: true,
  imports: [
    MatDialogTitle,
    MatDialogContent,
    MatFormField,
    FormsModule,
    MatInput,
    MatDialogActions,
    MatButton,
    MatFormFieldModule,
  ],
  templateUrl: './deposit-dialog.component.html',
  styleUrl: './deposit-dialog.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DepositDialogComponent {
  amountToDeposit!: number;
  wallet = '0x8F957B0272ad30a77C9ABf7cdb248BFb141cBf81';
  constructor(public dialogRef: MatDialogRef<DepositDialogComponent>) {}

  onConfirm(): void {
    this.dialogRef.close(this.amountToDeposit);
  }
}
