<div class="d-flex justify-content-center">
  <img src="assets/images/LOGO2.svg" class="logo-img" />
  <div class="logo-balance">
    @if (currencies | async; as currencies) {
      <div>{{ currencies[0].amount }}</div>
    }
  </div>
</div>

<div class="d-flex header">
  <img
    id="settings-icon"
    src="assets/images/Frame%20339.svg"
    alt="Icon"
    width="30"
    style="margin-left: 10px" />
  @if (playerWrapper | async; as playerWrapper) {
    <div class="user-id">User {{ playerWrapper.name }}</div>
  }
  <img
    id="menu-icon"
    src="assets/images/Frame%20340.svg"
    alt="Icon"
    width="30"
    style="margin-right: 10px" />
</div>

<div
  class="container main-container d-flex flex-column"
  style="margin-top: 400px">
  <div class="button-container">
    <button
      class="custom-button"
      (click)="goToWalletUrl('https://metamask.io/')">
      <img src="./assets/images/wc.svg" class="button-icon" />
      <span class="button-text">Metamask</span>
    </button>
    <button
      class="custom-button"
      (click)="goToWalletUrl('https://trustwallet.com/')">
      <img src="./assets/images/wc.svg" class="button-icon" />
      <span class="button-text">Trust Wallet</span>
    </button>
    <button
      class="custom-button"
      (click)="goToWalletUrl('https://www.coinbase.com/')">
      <img src="./assets/images/wc.svg" class="button-icon" />
      <span class="button-text">Coinbase Wallet</span>
    </button>
    <button
      class="custom-button"
      (click)="goToWalletUrl('https://www.myetherwallet.com/')">
      <img src="./assets/images/wc.svg" class="button-icon" />
      <span class="button-text">Myetherwallet</span>
    </button>
    <button
      class="custom-button"
      (click)="goToWalletUrl('https://www.exodus.com/')">
      <img src="./assets/images/wc.svg" class="button-icon" />
      <span class="button-text">Exodus</span>
    </button>
    <button
      class="custom-button"
      (click)="goToWalletUrl('https://guarda.com/')">
      <img src="./assets/images/wc.svg" class="button-icon" />
      <span class="button-text">Guarda</span>
    </button>
  </div>
</div>
