import { inject } from '@angular/core';
import { HttpInterceptorFn } from '@angular/common/http';
import { switchMap } from 'rxjs/operators';
import { from } from 'rxjs';
import { AuthService } from '../guards/auth.service';

export const authInterceptor: HttpInterceptorFn = (req, next) => {
  const authService = inject(AuthService) as AuthService;

  if (req.url.includes('/auth/refresh') || req.url.includes('/auth/login')) {
    return next(req);
  }
  if (authService.isTokenExpired()) {
    return from(authService.refreshToken()).pipe(
      switchMap(() => {
        const token = localStorage.getItem('token');
        const clonedReq = req.clone({
          headers: req.headers.set('Authorization', `Bearer ${token}`),
        });
        return next(clonedReq);
      }),
    );
  } else {
    const token = localStorage.getItem('token');
    const clonedReq = req.clone({
      headers: req.headers.set('Authorization', `Bearer ${token}`),
    });
    return next(clonedReq);
  }
};
